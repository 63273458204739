import { customElement } from 'aurelia-framework';
import { Bar, IChartistBarChart } from 'chartist';
import 'chartist/dist/chartist.css';
import './bar-chart.css';

@customElement('bar-chart')
export class BarChartCustomELement {
  private barchart: HTMLDivElement;
  private barInstance: IChartistBarChart;
  private labels: string[];
  private series: number[] | number[][];

  updateChart() {
    if (this.barchart === null) return;

    this.barInstance = new Bar(this.barchart, {
      labels: this.labels,
      series: this.series
    });
  }

  detached() {
    if (this.barInstance) this.barInstance.detach();
  }
}
