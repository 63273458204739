import './sitedetailschanneldetails.css';
import {
  routeNames,
  TIME,
  SecurityLevels,
  features
} from '../../../../../config';
import { getLogger } from 'aurelia-logging';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import {
  IChannel,
  IProduct,
  IUnit,
  AccessLevel
} from '../../../../../interfaces';
import {
  ChannelService,
  ProductService,
  UnitService,
  ControllerService
} from '../../../../../services';
import {
  ensureNumber,
  setBeginningOfDay,
  setEndOfDay,
  userHasFeature,
  getTimezoneByIdOrDefault
} from '../../../../../utility';
import { IAsyncEntity } from '../../../../../types';
import { IChannelDetails } from '../../../../controllermanager/controllerchannels/controllerchannels';
import { getSession } from '../../../../../config/sessionService';
import { IPoint, IChannelTrendStatsDetails } from '../../../../../models';
import { GraphQLBaseViewModel } from '../../../../common/GraphQLBaseViewModel';
import gql from 'graphql-tag';
import { TankTrendChartChannelDetailsFragmentDeclaration } from '../../../../../components/charts/tanktrendchart/tanktrendchart';
import { SiteDetailsChannelDetailsQuery, SiteDetailsChannelDetailsQueryVariables } from '../../../../../../custom_typings/graphql';

interface ISiteDetailsChannelsDetailsState {
  channel: IAsyncEntity<IChannelDetails>;
  history: IAsyncEntity<IPoint[]>;
  historyStats: IAsyncEntity<IChannelTrendStatsDetails[]>;
  from: Date;
  isRemote: boolean;
  timezone: string | undefined;
  to: Date;
  access: {
    canEditChannel: boolean;
  };
}

type selectedDateRange =
  | 'today'
  | 'last7'
  | 'last30'
  | { from: Date; to: Date; input: boolean };

const getDateRange = (
  selected: selectedDateRange
): { from: Date; to: Date; input: boolean } => {
  if (typeof selected !== 'string') return selected;
  let from = new Date();
  const to = new Date();

  switch (selected) {
    case 'today':
      break;
    case 'last30':
      from = new Date(to.getTime() - TIME.DAYINMS * 30);
      break;
    case 'last7':
      from = new Date(to.getTime() - TIME.DAY6INMS);
      break;
  }

  setEndOfDay(to);
  setBeginningOfDay(from);

  return { from, to, input: false };
};

const mapChannelToChannelDetails = (units: IUnit[], products: IProduct[]) => (
  channel: IChannel
): IChannelDetails => ({
  ...channel,
  unit: units.find(u => u.unitId === channel.unitId),
  product: products.find(p => p.productId === channel.productId)
});

@autoinject()
export class SiteDetailsChannelDetails extends GraphQLBaseViewModel<
  ISiteDetailsChannelsDetailsState,
  SiteDetailsChannelDetailsQuery, 
  SiteDetailsChannelDetailsQueryVariables
> {
  constructor(
    private router: Router,
    private controllerService: ControllerService,
    private channelService: ChannelService,
    private unitService: UnitService,
    private productService: ProductService
  ) {
    super(getLogger('SiteDetailsChannelDetails'));
  }

  query = gql`
    query SiteDetailsChannelDetailsQuery($siteChannelId: Int!) {
      siteChannel(siteChannelId: $siteChannelId) {
        ...TankTrendChartChannelDetailsFragment
      }
    }
    ${TankTrendChartChannelDetailsFragmentDeclaration}
  `;

  activeTab: string;
  siteChannelId: string;
  selectedDateRange: selectedDateRange = 'today';
  showCustomerHistoryFilter = false;
  securityLevels = SecurityLevels;

  activate({ siteChannelId, tab }: { siteChannelId: string; tab: string }) {
    this.activeTab = tab;
    this.siteChannelId = siteChannelId;
    this.variables = {
      siteChannelId: ensureNumber(siteChannelId)
    }
    this.attachMapState(this.mapState(ensureNumber(siteChannelId)));
  }

  mapState = (
    siteChannelId: number
  ) => (): ISiteDetailsChannelsDetailsState => {
    const { from, to } = getDateRange(this.selectedDateRange);
    const channelFetcher = this.channelService.getSiteChannelBySiteChannelId(
      siteChannelId
    );

    const controllersFetcher = channelFetcher.bind(c =>
      this.controllerService.getControllersBySiteId(c.siteId)
    );

    const controllerFetcher = channelFetcher.map2(
      controllersFetcher,
      (channel, controllers) =>
        controllers.find(c => c.controllerId === channel.controllerId)
    );
    const timezoneFetcher = controllerFetcher.map(controller =>
      getTimezoneByIdOrDefault(controller && controller.timeZoneId)
    );
    const history = timezoneFetcher.bind(timezone =>
      this.channelService.getChannelHistoryForSiteChannelForRange(
        siteChannelId,
        from,
        to,
        timezone
      )
    );
    const units = this.unitService.fetchUnits();
    const products = this.productService.getAll();
    const isRemote = controllerFetcher
      .map2(
        channelFetcher,
        (controller, channel) =>
          !!controller && controller.siteId !== channel.siteId
      )
      .getEntityOrDefault(true);

    return {
      channel: channelFetcher
        .map3(units, products, (c, u, p) => mapChannelToChannelDetails(u, p)(c))
        .getAsyncEntity(),
      history: history
        .map(h => h.trendData)
        .map(h => h.points.map<IPoint>(point => ({ ts: point.ts, v: point.v })))
        .getAsyncEntity(),
      from,
      to,
      historyStats: history
        .map2(units, (h, u) =>
          h.statistics.map<IChannelTrendStatsDetails>(m => ({
            ...m,
            unit: u.find(unit => unit.unitId === m.unitId)
          }))
        )
        .getAsyncEntity(),
      isRemote,
      timezone: timezoneFetcher.getEntityOrUndefined(),
      access: {
        canEditChannel: userHasFeature(
          getSession(),
          features.siteDetailsChannels,
          AccessLevel.Write
        )
      }
    };
  };

  setCustomDateRange() {
    const dateRange = getDateRange(this.selectedDateRange);
    this.channelHistoryChanged({ ...dateRange, input: true }, true);
  }

  trendMoveLeft() {
    const delta = this.state.to.getTime() - this.state.from.getTime();

    const to = new Date(this.state.from.getTime() - 1);
    const from = new Date(to.getTime() - delta);
    if (typeof this.selectedDateRange !== 'string')
      this.channelHistoryChanged(
        { ...this.selectedDateRange, from, to },
        false
      );
    else this.channelHistoryChanged({ from, to, input: false }, false);
  }

  trendMoveRight() {
    const delta = this.state.to.getTime() - this.state.from.getTime();

    const from = new Date(this.state.to.getTime() + 1);
    const to = new Date(from.getTime() + delta);

    if (typeof this.selectedDateRange !== 'string')
      this.channelHistoryChanged(
        { ...this.selectedDateRange, from, to },
        false
      );
    else this.channelHistoryChanged({ from, to, input: false }, false);
  }

  isPastDate(date: Date) {
    return date < new Date();
  }

  channelCustomDateRangeChanged(dates: Date[]) {
    if (dates.length < 2) return;

    const [from, to] = dates;
    setBeginningOfDay(from);
    setEndOfDay(to);

    this.channelHistoryChanged({ from, to, input: true });
  }

  channelHistoryChanged(newDateRange: selectedDateRange, useCache = false) {
    this.selectedDateRange = newDateRange;
    const { from, to } = getDateRange(this.selectedDateRange);

    this.channelService.getChannelHistoryForSiteChannelForRange(
      ensureNumber(this.siteChannelId),
      from,
      to,
      this.state.timezone,
      useCache
    );
  }

  tabItemClick(newTab: string) {
    this.router.navigateToRoute(
      routeNames.sitedetailsChannelDetails,
      { siteChannelId: this.siteChannelId, tab: newTab },
      { replace: newTab !== 'info-edit' }
    );
  }

  navigateBackFromEdit() {
    this.router.navigateBack();
  }

  navigateBackToChannelList() {
    this.router.navigateToRoute(routeNames.sitedetailsChannelsList, undefined, {
      replace: true
    });
  }
}
